import { OrderTemplate } from '@msdyn365-commerce-modules/retail-actions';
import { Button, format } from '@msdyn365-commerce-modules/utilities';
import { FullProduct } from '@msdyn365-commerce/commerce-entities';
import { ICoreContext, IImageSettings } from '@msdyn365-commerce/core';
import { ProductList } from '@msdyn365-commerce/retail-proxy';
import * as React from 'react';
import { OrderTemplateProductsPreviewComponent } from './order-template-products-preview';

export interface IOrderTemplateComponentProps {
    className: string;
    orderTemplate: OrderTemplate;
    context: ICoreContext;
    imageSettings?: IImageSettings;
    actionButtonText: string;
    lineItemsText: string;
    orderTemplateAction(orderTemplate: ProductList): Promise<void>;
}

export const OrderTemplateComponent = (props: IOrderTemplateComponentProps) => {
    const { orderTemplate, className, imageSettings } = props;

    const onActionButtonClick = () => {
        return props.orderTemplateAction(props.orderTemplate.productList);
    };

    const products = orderTemplate.orderTemplateLines.map(line => line.fullProduct).filter(fullProduct => fullProduct);

    return (
        <div id={`${className}__id_${orderTemplate.productList.Id}`} className={`${className}__container`}>
            <div className={`${className}__item_header`}>
                <div className={`${className}__title`}>{orderTemplate.productList.Name}</div>
                <div className={`${className}__count`}>{format(props.lineItemsText, orderTemplate.totalLines)}</div>
            </div>
            <OrderTemplateProductsPreviewComponent
                context={props.context}
                className={className}
                products={products as FullProduct[]}
                imageSettings={imageSettings}
            />
            <div className={`${className}__footer`}>
                <Button
                    className={`${className}__action-button`}
                    title={ props.actionButtonText }
                    onClick={onActionButtonClick}
                >
                    { props.actionButtonText }
                </Button>
            </div>
        </div>
    );
};