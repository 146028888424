import { IActionContext } from '@msdyn365-commerce/core';
import { addOrderInvoiceAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { Cart } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { ICartActionResultWithCart } from './cart-action-result';

export default async function addOrderInvoiceToCartInternal(cart: Readonly<Cart>, invoiceId: string, lineDescription: string, actionContext: IActionContext): Promise<ICartActionResultWithCart> {
    if (!invoiceId || invoiceId==='') {
        actionContext.telemetry.trace('Invoice id is empty, unable to add invoice to cart.');
        return { cart: cart, status: 'FAILED' };
    }

    if (cart.Version) {
        return addOrderInvoiceAsync({ callerContext: actionContext }, cart.Id, invoiceId, lineDescription)
        .then<ICartActionResultWithCart>(newCart => {
            return <ICartActionResultWithCart> { cart: newCart, status: 'SUCCESS' };
        })
        .catch(error => {
            actionContext.telemetry.trace(error);
            actionContext.telemetry.trace('Unable to add order invoice to cart');

            return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED'};
        });
    } else {
        actionContext.telemetry.warning('Unable to add order invoice, Cart Version could not be found');
    }

    return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED' };
}