import { createOrderTemplate, CreateOrderTemplateInput, updateOrderTemplateHeader, UpdateOrderTemplateHeaderInput } from '@msdyn365-commerce-modules/retail-actions';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '@msdyn365-commerce-modules/utilities';
import { IComponentProps } from '@msdyn365-commerce/core';
import { ProductList } from '@msdyn365-commerce/retail-proxy';
import React, { useState } from 'react';

export interface IOrderTemplateNameDialogResources {
    orderTemplateHeaderLabel: string;
    orderTemplateTitleLabel: string;
    orderTemplateNameAriaLabel: string;
    orderTemplateDescription: string;
    defaultOrderTemplateName: string;
    orderTemplateButtonLabel: string;
    orderTemplateCancelButtonLabel: string;
}

export interface IOrderTemplateNameDialogProps extends IComponentProps {
    isUpdateDialog: boolean | undefined;
    existingOrderTemplate?: ProductList;
    className: string;
    orderTemplateName?: string;
    dialogStrings: IOrderTemplateNameDialogResources;
    modalOpen: boolean | undefined;
    setModalOpen(newValue: boolean): void;
    onCancel(): void;
    onSuccess(orderTemplate: ProductList): Promise<void>;
}

export const OrderTemplateNameDialog = (dialogProps: IOrderTemplateNameDialogProps) => {
    const {
        isUpdateDialog,
        existingOrderTemplate,
        dialogStrings,
        className,
        modalOpen,
        setModalOpen,
        onCancel,
        onSuccess,
        context
    } = dialogProps;

    if (!dialogStrings) {
        return null;
    }

    const [orderTemplateName, setOrderTemplateName] = useState(dialogProps.orderTemplateName);

    const cancel: () => void = () => {
        setModalOpen(false);
        onCancel();
    };

    const submit: () => Promise<void> = async () => {
        dialogProps.orderTemplateName = orderTemplateName || dialogStrings.defaultOrderTemplateName;
        if (isUpdateDialog) {
            if (existingOrderTemplate) {
                existingOrderTemplate.Name = dialogProps.orderTemplateName;
                const input: UpdateOrderTemplateHeaderInput = new UpdateOrderTemplateHeaderInput(existingOrderTemplate);
                const orderTemplate: ProductList = await updateOrderTemplateHeader(input, context.actionContext);
                setModalOpen(false);
                await onSuccess(orderTemplate);
            } else {
                context.telemetry.error('You need to pass ProductList instance for update.');
                throw Error('Unexpected error: existing order template was not set.');
            }
        } else {
            const input: CreateOrderTemplateInput = new CreateOrderTemplateInput(dialogProps.orderTemplateName!);
            const orderTemplate: ProductList = await createOrderTemplate(input, context.actionContext);
            setModalOpen(false);
            await onSuccess(orderTemplate);
        }
    };

    const updateOrderTemplateName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOrderTemplateName(event.target.value);
    };

    let inputFieldRef: HTMLInputElement | null;
    const setFocus = () => { inputFieldRef?.focus(); };

    return (
        <Modal isOpen={modalOpen} className={`${className} msc-modal-input-required`} onOpened={setFocus} toggle={cancel}>
            <ModalHeader className={`${className}__dialog__header`} toggle={cancel}>
                <span>{dialogStrings.orderTemplateHeaderLabel}</span>
            </ModalHeader>
            <ModalBody className={`${className}__dialog__body`}>
                <div className={`${className}__dialog__content`}>
                    <label
                        className={`${className}__dialog__order-template-name-label`}
                        htmlFor={`${className}__dialog__order-template-name`}
                    >
                        {dialogStrings.orderTemplateTitleLabel}
                    </label>
                    <input
                        id={`${className}__dialog__order-template-name`}
                        className={`${className}__dialog__order-template-name`}
                        type='text'
                        aria-label={dialogStrings.orderTemplateNameAriaLabel}
                        placeholder={dialogStrings.defaultOrderTemplateName}
                        onChange={updateOrderTemplateName}
                        value={orderTemplateName}
                        maxLength={250}
                        ref={(inputRef) => { inputFieldRef = inputRef; }}
                    />
                    <div className={`${className}__dialog__description`}>{dialogStrings.orderTemplateDescription}</div>
                </div>
            </ModalBody>
            <ModalFooter
                className={`${className}__dialog__footer`}
            >
                <Button
                    className={`${className}__dialog__create-order-template-button`}
                    title={dialogStrings.orderTemplateButtonLabel}
                    onClick={submit}
                >
                    {dialogStrings.orderTemplateButtonLabel}
                </Button>
                <Button
                    className={`${className}__dialog__cancel-button`}
                    title={dialogStrings.orderTemplateCancelButtonLabel}
                    onClick={cancel}
                >
                    {dialogStrings.orderTemplateCancelButtonLabel}
                </Button>
            </ModalFooter>
        </Modal>
    );
};