import classnames from 'classnames';
import * as Popper from 'popper.js';
import React, { useState } from 'react';

import {
    addItemToOrderTemplate, AddItemToOrderTemplateInput,
    getPriceForSelectedVariant, PriceForSelectedVariantInput,
    updateOrderTemplateAction, UpdateOrderTemplateInput } from '@msdyn365-commerce-modules/retail-actions';
import { OrderTemplate } from '@msdyn365-commerce-modules/retail-actions';
import { Alert, UncontrolledTooltip } from '@msdyn365-commerce-modules/utilities';
import { IComponent, IComponentProps, msdyn365Commerce } from '@msdyn365-commerce/core';
import { AsyncResult, Customer, ProductDimension, ProductList, ProductPrice, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { AddToOrderTemplateDialog, IAddToOrderTemplateDialogData, IAddToOrderTemplateDialogResources } from '../add-to-order-template-dialog/add-to-order-template-dialog.component';
import { DuplicateItemsWhenAddingToOrderTemplateDialog, IDuplicateItemsWhenAddingToOrderTemplateDialogResources } from '../duplicate-items-when-adding-to-order-template-dialog/duplicate-items-when-adding-to-order-template-dialog.component';
import { IItemAddedToOrderTemplateDialogResources, ItemAddedToOrderTemplateDialog } from '../item-added-to-order-template-dialog/item-added-to-order-template-dialog.component';
import { IOrderTemplateNameDialogResources } from '../order-template-name-dialog/order-template-name-dialog.component';

export interface IAddToOrderTemplateComponentProps extends IComponentProps<IAddToOrderTemplateData> {
    className?: string;

    addToOrderTemplateDialogResources: IAddToOrderTemplateDialogResources;
    createOrderTemplateDialogResources: IOrderTemplateNameDialogResources;
    itemAddedToOrderTemplateDialogResources: IItemAddedToOrderTemplateDialogResources;
    duplicateItemsWhenAddingToOrderTemplateDialogResources: IDuplicateItemsWhenAddingToOrderTemplateDialogResources;

    addToOrderTemplateButtonText: string;

    addToOrderTemplateMessage?: string;
    addItemToOrderTemplateError?: string;

    showButtonText?: boolean;
    showButtonTooltip?: boolean;
    tooltipPosition?: Popper.Placement;
    showStatusMessage?: boolean;
    disabled?: boolean;
    canAddMasterProduct?: boolean;

    onSuccess?(): void;
    onError?(result: IOrderTemplateActionErrorResult): void;
}

export interface IOrderTemplateActionErrorResult {
    status: 'FAILED' | 'MISSING_DIMENSION';

    missingDimensions?: ProductDimension[];
}

interface IAddToOrderTemplateStateManager {
    waiting: boolean;
    content: IAddToOrderTemplateContentState;

    setWaiting(newWaiting: boolean): void;
    setContent(newContent: IAddToOrderTemplateContentState): void;
}

interface IAddToOrderTemplateContentState {
    showAlert?: boolean;
    orderTemplateTextMessage?: string;
    color?: string;
}

interface IAddToOrderTemplateData {
    orderTemplates: OrderTemplate[];

    product: SimpleProduct;
    quantity: number;

    customerInformation?: Customer;
}

export interface IAddToOrderTemplateComponent extends IComponent<IAddToOrderTemplateComponentProps> {
    onClick(): (event: React.MouseEvent<HTMLElement>, props: IAddToOrderTemplateComponentProps, state: IAddToOrderTemplateStateManager) => void;
}

const onClick = async (
    _event: React.MouseEvent<HTMLElement>,
    props: IAddToOrderTemplateComponentProps,
    state: IAddToOrderTemplateStateManager,
    openModal: (opened: boolean) => void): Promise<void> => {

    const { user } = props.context.request;

    if (state.waiting) {
        return;
    }

    if (!user.isAuthenticated && user.signInUrl && window) {
        window.location.assign(user.signInUrl);
        return;
    }

    addToOrderTemplate(props, openModal);
    state.setWaiting(false);
};

const onDismiss = (state: IAddToOrderTemplateStateManager): void => {
    state.setContent({ color: 'success', showAlert: false });
};

const AddToOrderTemplateComponentActions = {
    onClick: onClick,
    onDismiss: onDismiss
};

const hasProductInOrderTemplates = (productToFind: SimpleProduct) => {
    /*
    TODO: Currently API does not support filtering by products.
    await getProductListLinesAsync(context, '',)
    return orderTemplates.filter(orderTemplate =>
        orderTemplate.orderTemplateLines.filter(
            product => product.productListLine.ProductId === productToFind.RecordId).length !== 0).length !== 0;
    */
   return false;
};

// tslint:disable-next-line:max-func-body-length
const AddToOrderTemplate: React.FC<IAddToOrderTemplateComponentProps> = (props: IAddToOrderTemplateComponentProps) => {
    if (!props.data.customerInformation?.IsB2b) {
        return null;
    }

    const [waiting, setWaiting] = useState(false);
    const [content, setContent] = useState({});
    const state: IAddToOrderTemplateStateManager = {
        waiting,
        content,

        setWaiting,
        setContent
    };

    const [addToOrderTemplateModalOpen, setAddToOrderTemplateModalOpen] = useState(false);

    const [chosenOrderTemplateId, setChosenOrderTemplateId] = useState<string | undefined>(undefined);
    const [productPrice, setProductPrice] = useState<ProductPrice | null>(null);
    const [itemAddedToOrderTemplateDialogOpen, setItemAddedToOrderTemplateDialogOpen] = useState(false);
    const [duplicateItemsWhenAddingToOrderTemplateDialogOpen, setDuplicateItemsWhenAddingToOrderTemplateDialogOpen] = useState(false);

    const executeAddItemToOrderTemplate = async (orderTemplate: ProductList) => {
        setChosenOrderTemplateId(orderTemplate.Id);

        const input = new AddItemToOrderTemplateInput(
            orderTemplate.Id,
            props.data.product.RecordId,
            props.data.quantity,
            props.data.product.DefaultUnitOfMeasure || 'ea');

        await addItemToOrderTemplate(input, props.context.actionContext).then(async result => {
            const retrievedProductPrice = await getPriceForSelectedVariant(
                new PriceForSelectedVariantInput(
                    props.data.product.RecordId,
                    props.context.actionContext.requestContext.apiSettings.channelId,
                    props.data.product),
                props.context.actionContext);
            setProductPrice(retrievedProductPrice);
            setAddToOrderTemplateModalOpen(false);
            setTimeout(() => {
                setItemAddedToOrderTemplateDialogOpen(true);
            }, 500); // tslint:disable-line:align
        }).catch(result => {
            if (result.error?.name !== 'Microsoft_Dynamics_Commerce_Runtime_DuplicateObject') {
                return AsyncResult.reject(result);
            }
            setAddToOrderTemplateModalOpen(false);
            setTimeout(() => {
                setDuplicateItemsWhenAddingToOrderTemplateDialogOpen(true);
            }, 500); // tslint:disable-line:align
            return AsyncResult.resolve();
        });
    };

    const onDuplicateDialogCancel = () => {
        setDuplicateItemsWhenAddingToOrderTemplateDialogOpen(false);
        setTimeout(() => {
            setAddToOrderTemplateModalOpen(true);
        }, 500); // tslint:disable-line:align
    };

    const overrideQuantity = async () => {
        const input = new UpdateOrderTemplateInput(
            chosenOrderTemplateId!,
            props.data.product.RecordId,
            props.data.quantity,
            props.data.product.DefaultUnitOfMeasure || 'ea');
        await updateOrderTemplateAction(input, props.context.actionContext);

        const retrievedProductPrice = await getPriceForSelectedVariant(
            new PriceForSelectedVariantInput(
                props.data.product.RecordId,
                props.context.actionContext.requestContext.apiSettings.channelId,
                props.data.product),
            props.context.actionContext);
        setProductPrice(retrievedProductPrice);
        setDuplicateItemsWhenAddingToOrderTemplateDialogOpen(false);
        setTimeout(() => {
            setItemAddedToOrderTemplateDialogOpen(true);
        }, 500); // tslint:disable-line:align
    };

    const { addToOrderTemplateButtonText, showStatusMessage, showButtonText,
        className, disabled, showButtonTooltip, tooltipPosition,
        addToOrderTemplateDialogResources, createOrderTemplateDialogResources
    } = props;

    const onDismissHandler = () => { return AddToOrderTemplateComponentActions.onDismiss(state); };
    const ariaLabel = addToOrderTemplateButtonText;
    const addToOrderTemplateRef: React.RefObject<HTMLButtonElement> = React.createRef();
    const showMessage = showStatusMessage !== undefined ? showStatusMessage: true;
    const showTooltip = showButtonTooltip !== undefined ? showButtonTooltip: true;
    const tooltipPos = showButtonTooltip !== undefined ? tooltipPosition: 'top-end';

    const dialogData: IAddToOrderTemplateDialogData = {
        orderTemplates: props.data.orderTemplates
    };

    const onClickHandler = (event: React.MouseEvent<HTMLElement>) => { return AddToOrderTemplateComponentActions.onClick(event, props, state, setAddToOrderTemplateModalOpen); };

    return (
        <React.Fragment>
            {
                <>
                    <button
                        className={classnames(
                            className,
                            hasProductInOrderTemplates(props.data.product) ? 'msc-add-to-order-template-icon__added' : 'msc-add-to-order-template-icon' ,
                            state.waiting ? 'msc-add-to-order-template-icon__waiting' : '')}
                        aria-label={ariaLabel}
                        onClick={onClickHandler}
                        disabled={disabled}
                        ref={addToOrderTemplateRef}
                    >
                        {showButtonText ? ariaLabel : ''}
                    </button>
                    {
                        showTooltip && <UncontrolledTooltip placement={tooltipPos} trigger={'hover focus'} target={addToOrderTemplateRef}>{ariaLabel}</UncontrolledTooltip>
                    }
                </>
            }
            {
                state.content.showAlert && showMessage ?
                <div className='ms-order-template-items__product-status'>
                    <Alert color={ state.content.color } isOpen={ state.content.showAlert } toggle={ onDismissHandler }>
                        { state.content.orderTemplateTextMessage }
                    </Alert>
                </div> :
                ''
            }
            <AddToOrderTemplateDialog
                className={'msc-add-to-order-template-dialog'}
                modalOpen={addToOrderTemplateModalOpen}
                setModalOpen={setAddToOrderTemplateModalOpen}
                context={props.context}
                id={props.id}
                typeName={props.typeName}
                data={dialogData}
                addToOrderTemplateDialogResources={addToOrderTemplateDialogResources}
                createOrderTemplateDialogResources={createOrderTemplateDialogResources}
                onOrderTemplateSelected={executeAddItemToOrderTemplate}
            />
            {
                chosenOrderTemplateId && productPrice && <ItemAddedToOrderTemplateDialog
                    className={'msc-item-added-to-order-template-dialog'}
                    id={props.id}
                    typeName={props.typeName}
                    context={props.context}
                    dialogStrings={props.itemAddedToOrderTemplateDialogResources}
                    data={{
                        product: props.data.product,
                        quantity: props.data.quantity,
                        orderTemplateId: chosenOrderTemplateId,
                        productPrice: productPrice
                    }}
                    modalOpen={itemAddedToOrderTemplateDialogOpen}
                />
            }
            <DuplicateItemsWhenAddingToOrderTemplateDialog
                className={'msc-duplicate-items-when-adding-to-order-template-dialog'}
                id={props.id}
                typeName={props.typeName}
                context={props.context}
                dialogStrings={props.duplicateItemsWhenAddingToOrderTemplateDialogResources}
                data={{}}
                modalOpen={duplicateItemsWhenAddingToOrderTemplateDialogOpen}
                executeOverrideQuantity={overrideQuantity}
                onCancel={onDuplicateDialogCancel}
            />
        </React.Fragment>
    );
};

const addToOrderTemplate = (
    props: IAddToOrderTemplateComponentProps,
    openModal: (opened: boolean) => void): void => {
    const { canAddMasterProduct } = props;

    if (props.data.product.Dimensions && !canAddMasterProduct) {
        const missingDimensions = props.data.product.Dimensions.filter(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value));

        if (missingDimensions.length > 0) {
            // At least one dimension with no value exists on the product, won't be able to proceed.
            propagateError(props, { status: 'MISSING_DIMENSION', missingDimensions: missingDimensions });
            return;
        }
    }

    openModal(true);
    return;
};

const propagateError = (props: IAddToOrderTemplateComponentProps, result: IOrderTemplateActionErrorResult): void => {
    const { onError } = props;

    if (onError) {
        onError(result);
    }
};

// @ts-ignore
export const AddToOrderTemplateComponent: React.FunctionComponent<IAddToOrderTemplateComponentProps> = msdyn365Commerce.createComponent<IAddToOrderTemplateComponent>(
    'AddToOrderTemplate',
    { component: AddToOrderTemplate, ...AddToOrderTemplateComponentActions }
);