import { FullProductInput, getFullProducts, getProductDetailsCriteriaFromActionInput } from '@msdyn365-commerce-modules/retail-actions';
import { OrderTemplate } from '@msdyn365-commerce-modules/retail-actions';
import { Button } from '@msdyn365-commerce-modules/utilities';
import { FullProduct } from '@msdyn365-commerce/commerce-entities';
import { IComponent, IComponentProps, msdyn365Commerce } from '@msdyn365-commerce/core';
import { CartLine, Customer, ProductList, ProductListLine } from '@msdyn365-commerce/retail-proxy';
import { addProductListLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductListsDataActions.g';
import React, { useState } from 'react';
import { AddToOrderTemplateDialog, IAddToOrderTemplateDialogResources } from '../add-to-order-template-dialog/add-to-order-template-dialog.component';
import { ILinesAddedToOrderTemplateDialogResources, LinesAddedToOrderTemplateDialog } from '../lines-added-to-order-template-dialog/lines-added-to-order-template-dialog.component';
import { IOrderTemplateNameDialogResources } from '../order-template-name-dialog/order-template-name-dialog.component';

export interface ISaveCartToOrderTemplateComponentProps extends IComponentProps<ISaveCartToOrderTemplateData> {
    className?: string;

    addToOrderTemplateDialogResources: IAddToOrderTemplateDialogResources;
    createOrderTemplateDialogResources: IOrderTemplateNameDialogResources;
    linesAddedToOrderTemplateDialogResources: ILinesAddedToOrderTemplateDialogResources;

    createOrderTemplateFromCartButton: string;
}

interface ISaveCartToOrderTemplateData {
    orderTemplates: OrderTemplate[];
    cartLines: CartLine[];

    customerInformation?: Customer;
}

interface ISaveCartToOrderTemplateContentState {
    showAlert?: boolean;
    orderTemplateTextMessage?: string;
    color?: string;
}

interface ISaveCartToOrderTemplateStateManager {
    waiting: boolean;
    content: ISaveCartToOrderTemplateContentState;

    setWaiting(newWaiting: boolean): void;
    setContent(newContent: ISaveCartToOrderTemplateContentState): void;
}

export interface ISaveCartToOrderTemplateComponent extends IComponent<ISaveCartToOrderTemplateComponentProps> {
    onClick(): (event: React.MouseEvent<HTMLElement>, props: ISaveCartToOrderTemplateComponentProps, state: ISaveCartToOrderTemplateStateManager) => void;
}

const onDismiss = (state: ISaveCartToOrderTemplateStateManager): void => {
    state.setContent({ color: 'success', showAlert: false });
};

const SaveCartToOrderTemplateActions = {
    onDismiss: onDismiss
};

// tslint:disable-next-line:max-func-body-length
const SaveCartToOrderTemplate: React.FC<ISaveCartToOrderTemplateComponentProps> = (props: ISaveCartToOrderTemplateComponentProps) => {
    if (!props.data.customerInformation?.IsB2b) {
        return null;
    }

    const [chosenOrderTemplate, setChosenOrderTemplate] = useState<ProductList | undefined>(undefined);
    const [addedProducts, setAddedProducts] = useState<FullProduct[] | undefined>(undefined);
    const [addToOrderTemplateModalOpen, setAddToOrderTemplateModalOpen] = useState(false);
    const [linesAddedToOrderTemplateDialogOpen, setLinesAddedToOrderTemplateDialogOpen] = useState(false);
    const [addedLinesCount, setAddedLinesCount] = useState(0);
    const [addedItemsCount, setAddedItemsCount] = useState(0);

    const {
        addToOrderTemplateDialogResources, createOrderTemplateDialogResources,
        createOrderTemplateFromCartButton
    } = props;

    const onOrderTemplateSelected = async (orderTemplate: ProductList) => {
        setChosenOrderTemplate(orderTemplate);
        const productListLines = props.data.cartLines.map(cartLine => {
            // tslint:disable-next-line:no-unnecessary-local-variable
            const productListLineToAdd: ProductListLine = {
                ProductListId: orderTemplate.Id,
                ProductId: cartLine.ProductId,
                Quantity: cartLine.Quantity,
                UnitOfMeasure: cartLine.UnitOfMeasureSymbol
            };
            return productListLineToAdd;
        });

        const addedLines = await addProductListLinesAsync({ callerContext: props.context.actionContext }, orderTemplate.Id, productListLines);
        const productInputs: FullProductInput[] = [];
        addedLines.forEach(listLine => {
            if (listLine.ProductId) {
                productInputs.push(new FullProductInput(
                    listLine.ProductId,
                    props.context.actionContext.requestContext.apiSettings,
                    getProductDetailsCriteriaFromActionInput(props.context.actionContext)));
            }
        });
        const products = await getFullProducts(productInputs, props.context.actionContext);

        setAddedLinesCount(addedLines.length);
        setAddedItemsCount(addedLines.map(line => line.Quantity || 1).reduce((firstQuantity, secondQuantity) => firstQuantity + secondQuantity));
        setAddedProducts(products);

        setAddToOrderTemplateModalOpen(false);
        setTimeout(() => {
            setLinesAddedToOrderTemplateDialogOpen(true);
        }, 500); // tslint:disable-line:align
    };

    const onSaveCartToOrderTemplateClick = () => {
        setAddToOrderTemplateModalOpen(true);
    };

    return (
        <React.Fragment>
            <AddToOrderTemplateDialog
                className={'msc-add-to-order-template-dialog'}
                modalOpen={addToOrderTemplateModalOpen}
                setModalOpen={setAddToOrderTemplateModalOpen}
                context={props.context}
                id={props.id}
                typeName={props.typeName}
                data={{ orderTemplates: props.data.orderTemplates }}
                addToOrderTemplateDialogResources={addToOrderTemplateDialogResources}
                createOrderTemplateDialogResources={createOrderTemplateDialogResources}
                onOrderTemplateSelected={onOrderTemplateSelected}
            />
            {
                chosenOrderTemplate && addedProducts && <LinesAddedToOrderTemplateDialog
                    className={'msc-lines-added-to-order-template-dialog'}
                    id={props.id}
                    typeName={props.typeName}
                    context={props.context}
                    dialogStrings={props.linesAddedToOrderTemplateDialogResources}
                    data={{
                        linesCount: addedLinesCount,
                        itemsCount: addedItemsCount,
                        orderTemplate: chosenOrderTemplate,
                        products: addedProducts
                    }}
                    modalOpen={linesAddedToOrderTemplateDialogOpen}
                />
            }
            <Button className={'msc-cart__btn-addcarttotemplate'} onClick={onSaveCartToOrderTemplateClick} title={createOrderTemplateFromCartButton}>{createOrderTemplateFromCartButton}</Button>
        </React.Fragment>
    );
};

// @ts-ignore
export const SaveCartToOrderTemplateComponent: React.FunctionComponent<ISaveCartToOrderTemplateComponentProps> = msdyn365Commerce.createComponent<ISaveCartToOrderTemplateComponent>(
    'SaveCartToOrderTemplate',
    { component: SaveCartToOrderTemplate, ...SaveCartToOrderTemplateActions }
);