import { getFallbackImageUrl } from '@msdyn365-commerce-modules/retail-actions';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '@msdyn365-commerce-modules/utilities';
import { getUrlSync, IComponentProps, ICoreContext, IImageSettings, Image } from '@msdyn365-commerce/core';
import { ProductPrice, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import React from 'react';
import { PriceComponent } from '../../price/price.components';

export interface IItemAddedToCartDialogResources {
    viewCartButtonText: string;
    orderTemplateCloseButtonText: string;
    itemAddedToCartHeaderItemOneText: string;
    itemAddedToCartHeaderItemFormatText: string;
    itemAddedToCartHeaderMessageText: string;

    freePriceText: string;
    originalPriceText: string;
    currentPriceText: string;
}

export interface IItemAddedToCartDialogData {
    product: SimpleProduct;
    productPrice: ProductPrice;
    quantity: number;
}

export interface IItemAddedToCartDialogProps extends IComponentProps<IItemAddedToCartDialogData> {
    className: string;

    dialogStrings: IItemAddedToCartDialogResources;

    modalOpen: boolean | undefined;
    onClose(): void;
}

const renderImage = (
    context: ICoreContext, className: string, product: SimpleProduct, imageSettings?: IImageSettings) => {
    const fallbackImageUrl = getFallbackImageUrl(product.ItemId, context.actionContext.requestContext.apiSettings);
    const defaultImageSettings: IImageSettings = {
        viewports: {
            xs: { q: `w=140&h=140&m=6`, w: 0, h: 0 },
            lg: { q: `w=140&h=140&m=6`, w: 0, h: 0 },
            xl: { q: `w=140&h=140&m=6`, w: 0, h: 0 }
        },
        lazyload: true
    };
    return (
        <Image
            className={`${className}__image-props`}
            src={product.PrimaryImageUrl || ''}
            altText={product.Name}
            fallBackSrc = {fallbackImageUrl}
            gridSettings={context.request.gridSettings!}
            imageSettings={imageSettings || defaultImageSettings}
            loadFailureBehavior='empty'
        />
    );
};

export const ItemAddedToCartDialog = (dialogProps: IItemAddedToCartDialogProps) => {
    const {
        dialogStrings,
        className,
        context,
        modalOpen,
        data: {
            product,
            productPrice,
            quantity
        }
    } = dialogProps;

    const getCartPageUrl = (): string => {
        return getUrlSync('cart', context.actionContext) || '';
    };

    const close: () => void = () => {
        dialogProps.onClose();
    };

    const itemsString = quantity === 1 ? dialogStrings.itemAddedToCartHeaderItemOneText : dialogStrings.itemAddedToCartHeaderItemFormatText.replace('{0}', quantity.toString());

    return (
        <Modal isOpen={modalOpen} className={`${className}`} toggle={close}>
            <ModalHeader className={`${className}__dialog__header`} toggle={close}>
                <b>{itemsString}</b><span>{dialogStrings.itemAddedToCartHeaderMessageText}</span>
            </ModalHeader>
            <ModalBody className={`${className}__dialog__body`}>
                <div className={`${className}__dialog__content`}>
                    {renderImage(context, `${className}__dialog`, product)}
                    <div className={`${className}__dialog__description`}>
                        <div className={`${className}__dialog__product-name`}>
                            {product.Name}
                        </div>
                        <div className={`${className}__dialog__product-price`}>
                            {
                                productPrice && <PriceComponent
                                    data={{price: productPrice}}
                                    context={dialogProps.context}
                                    id={dialogProps.id}
                                    typeName={dialogProps.typeName}
                                    freePriceText={dialogProps.dialogStrings.freePriceText}
                                    originalPriceText={dialogProps.dialogStrings.originalPriceText}
                                    currentPriceText={dialogProps.dialogStrings.currentPriceText}
                                />
                            }
                        </div>
                        {
                            product.Dimensions &&
                                <div className={`${className}__dialog__product-dimensions`}>
                                    {product.Dimensions.map(dimension => dimension.DimensionValue?.Value).filter(value => value).join(', ')}
                                </div>
                        }
                    </div>
                </div>
            </ModalBody>
            <ModalFooter
                className={`${className}__dialog__footer`}
            >
                <Button
                    className={`${className}__dialog__view-cart-button`}
                    title={dialogStrings.viewCartButtonText}
                    href={getCartPageUrl()}
                >
                    {dialogStrings.viewCartButtonText}
                </Button>
                <Button
                    className={`${className}__dialog__continue-shopping`}
                    title={dialogStrings.orderTemplateCloseButtonText}
                    onClick={close}
                >
                    {dialogStrings.orderTemplateCloseButtonText}
                </Button>
            </ModalFooter>
        </Modal>
    );
};