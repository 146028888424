import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, ICommerceApiSettings, ICreateActionContext } from '@msdyn365-commerce/core';
import { Cart } from '@msdyn365-commerce/retail-proxy';
import { ICartState } from '../state-interfaces';
import { getCartState } from './get-cart-state';

/**
 * Input class for getCart data action
 */
export class GetCartStateDataInput implements IActionInput {
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings) {
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => `GetCart-chanId:${this.apiSettings.channelId}-catId:${this.apiSettings.catalogId}`;
    public getCacheObjectType = () => 'Cart';
    public dataCacheType = (): CacheType => 'none';
}

const createInput = (inputData: ICreateActionContext) => {
    return new GetCartStateDataInput(inputData.requestContext.apiSettings);
};

/**
 * Data action to copy the cart for  actions
 */
export async function getCartStateData(input: GetCartStateDataInput, ctx: IActionContext): Promise<ICartState | undefined> {

    const cartState = await getCartState(ctx);
    if (cartState && cartState.cart.Id !== undefined) {
        return cartState;
    }

    return undefined;
}

export default createObservableDataAction({
    id: '@msdyn365-commerce/global-state/get-cart-state-data',
    action: <IAction<Cart | undefined>>getCartStateData,
    input: createInput
});