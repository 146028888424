import * as React from 'react';

import { IComponent, IComponentProps, IImageData } from '@msdyn365-commerce/core';

export enum SocialMedia {
    Facebook = 'facebook',
    Twitter = 'twitter',
    Pinterest = 'pinterest',
    Mail = 'mail',
    Linkedin = 'linkedin',
    Customization = 'customization'
}

export interface ISocialMediaProps extends IComponentProps<{}> {
    src?: IImageData;
    postUrl: string;
    postTitle?: string;
    socialMedia: SocialMedia;
    socialShareURL?: string;
    socialMediaName?: string;
}

export interface ISocialMediaComponent extends IComponent<ISocialMediaProps> {
}

/**
 *
 * SocialMedia component
 * @extends {React.FC<ISocialMediaProps>}
 */

export const SocialMediaComponent: React.FC<ISocialMediaProps> = (props) => {
    const openWindowFunc = (event: React.MouseEvent<HTMLElement>) => { return setShareLinks(props); };
    const src = props.src && props.src.src;
    return (
        <img onClick={openWindowFunc} src={src} alt={props.socialMediaName || props.socialMedia} title={props.socialMediaName || props.socialMedia} />
    );
};

const setShareLinks = (props: React.PropsWithChildren<ISocialMediaProps>): void => {
    const pageUrl = encodeURIComponent(props.postUrl);
    const mediaImage = document.getElementsByName('twitter:image')[0].getAttribute('content');
    let url: string | undefined;
    switch (props.socialMedia) {
        case SocialMedia.Facebook:
            url = `https://www.facebook.com/sharer.php?u=${pageUrl}`;
            break;
        case SocialMedia.Twitter:
            url = `https://twitter.com/share?url=${pageUrl}${props.postTitle ? `&text=${props.postTitle}` : ''}`;
            break;
        case SocialMedia.Pinterest:
            url = `https://www.pinterest.com/pin/create/button/?url=${pageUrl}${mediaImage ? `&media=${mediaImage}`: ''}${props.postTitle ? `&description=${props.postTitle}` : ''}`;
            break;
        case SocialMedia.Mail:
            url = `mailto:?body=Check%20out%20this%20site:${pageUrl}${props.postTitle ? `&subject=${props.postTitle}` : ''}`;
            break;
        case SocialMedia.Linkedin:
            url = `https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}${props.postTitle ? `&title=${props.postTitle}` : ''}`;
            break;
        case SocialMedia.Customization:
            url = props.socialShareURL && `${props.socialShareURL}${pageUrl}`;
            break;
        default:
    }

    url && openWindow(url);
};

function openWindow(url: string): void {
    const left = (screen.width - 570) / 2;
    const top = (screen.height - 570) / 2;
    const params = `menubar=no,toolbar=no,status=no,width=570,height=570,top='${top}',left='${left}`;
    // Setting 'params' to an empty string will launch
    // content in a new tab or window rather than a pop-up.
    // params = "";
    window.open(url, 'NewWindow', params);
}
