import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '@msdyn365-commerce-modules/utilities';
import { FullProduct } from '@msdyn365-commerce/commerce-entities';
import { getUrlSync, IComponentProps, IImageSettings } from '@msdyn365-commerce/core';
import { ProductList } from '@msdyn365-commerce/retail-proxy';
import React from 'react';
import { OrderTemplateProductsPreviewComponent } from '../order-templates-list/order-template-products-preview';

export interface ILinesAddedToCartDialogResources {
    viewCartButtonText: string;
    orderTemplateCloseButtonText: string;
    linesAddedToCartHeaderItemsOneText: string;
    linesAddedToCartHeaderLinesOneText: string;
    linesAddedToCartHeaderLinesFormatText: string;
    linesAddedToCartHeaderMessageText: string;
}

export interface ILinesAddedToCartDialogData {
    linesCount: number;
    orderTemplate: ProductList;
    products: FullProduct[];
}

export interface ILinesAddedToCartDialogProps extends IComponentProps<ILinesAddedToCartDialogData> {
    className: string;
    imageSettings?: IImageSettings;

    dialogStrings: ILinesAddedToCartDialogResources;

    modalOpen: boolean | undefined;
    onClose(): void;
}

export const LinesAddedToCartDialog = (dialogProps: ILinesAddedToCartDialogProps) => {
    const {
        dialogStrings,
        className,
        context,
        modalOpen,
        imageSettings,
        data: {
            orderTemplate,
            products,
            linesCount
        }
    } = dialogProps;

    const getCartPageUrl = (): string => {
        return getUrlSync('cart', context.actionContext) || '';
    };

    const close: () => void = () => {
        dialogProps.onClose();
    };

    const lineItemsString = linesCount === 1 ? dialogStrings.linesAddedToCartHeaderLinesOneText : dialogStrings.linesAddedToCartHeaderLinesFormatText.replace('{0}', linesCount.toString());

    return (
        <Modal isOpen={modalOpen} className={`${className}`} toggle={close}>
            <ModalHeader className={`${className}__dialog__header`} toggle={close}>
                <b>{dialogStrings.linesAddedToCartHeaderItemsOneText}</b><span>{dialogStrings.linesAddedToCartHeaderMessageText}</span>
            </ModalHeader>
            <ModalBody className={`${className}__dialog__body`}>
                <div className={`${className}__dialog__content`}>
                    <OrderTemplateProductsPreviewComponent
                        context={context}
                        className={`${className}__dialog`}
                        products={products}
                        imageSettings={imageSettings}
                    />
                    <div className={`${className}__dialog__description`}>
                        <div className={`${className}__dialog__order-template-name`}>
                            {orderTemplate.Name}
                        </div>
                        <div className={`${className}__dialog__order-template-items`}>
                            {lineItemsString}
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter
                className={`${className}__dialog__footer`}
            >
                <Button
                    className={`${className}__dialog__view-cart-button`}
                    title={dialogStrings.viewCartButtonText}
                    href={getCartPageUrl()}
                >
                    {dialogStrings.viewCartButtonText}
                </Button>
                <Button
                    className={`${className}__dialog__continue-shopping`}
                    title={dialogStrings.orderTemplateCloseButtonText}
                    onClick={close}
                >
                    {dialogStrings.orderTemplateCloseButtonText}
                </Button>
            </ModalFooter>
        </Modal>
    );
};